export default [
  ['ja', 'Japanese'],
  ['it', 'Italian'],
  ['zh-tw', 'Traditional Chinese'],
  ['cs', 'Czech'],
  ['ru', 'Russian'],
  ['zh-cn', 'Simplified Chinese'],
  ['bg', 'Bulgarian'],
  ['de', 'German'],
  ['fi', 'Finnish'],
  ['fr', 'French'],
  ['es', 'Spanish'],
  ['en', 'English'],
];
